import UserPreference, { UserPreferenceOptions, UserPreferenceType } from 'models/UserPreference';
import Notification, { NotificationOptions } from 'models/userPreferences/Notification';
import PortfolioFilter, { PortfolioFilterOptions } from 'models/userPreferences/PortfolioFilter';

export default class UserPreferenceModelFactory {
  private static defaultClass = UserPreference;

  private static mapping = {
    [UserPreferenceType.PORTFOLIO_FILTER]: PortfolioFilter,
    [UserPreferenceType.NOTIFICATION]: Notification,
  };

  /**
   * Returns an instance of the appropriate class based on the options provided.
   * @see {@link https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads Function Overloads}
   */
  static get(options: Partial<PortfolioFilterOptions>): PortfolioFilter;
  static get(options: Partial<NotificationOptions>): Notification;
  static get(options: Partial<UserPreferenceOptions>): UserPreference;
  static get(options): UserPreference {
    const mappedClass = options.type ? this.mapping[options.type] : this.defaultClass;
    const klass = mappedClass ?? this.defaultClass;
    return new klass(options);
  }
}
