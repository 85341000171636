import { useCallback, useEffect, useMemo } from 'react';
import { camelCase } from 'lodash';

import { useQuery } from '@tanstack/react-query';

import { DATES, END_DATE, START_DATE } from 'constants/filterKeysConstants';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import usePrevious from 'hooks/usePrevious';
import isDeepEqual from 'lib/isDeepEqual';
import { retrieveAnalytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';

import { DataRow } from './dataUtils';

type Request = {
  params: any;
  processData: (data: DataRow[]) => void;
};

type DataFetchConfig = {
  onIntersecting?: boolean;
};

//TODO: MIGRATE COMPONENTS USING THIS TO USE useInsightsQuery
export default function useDataFetch(requests: Request[], config: DataFetchConfig = {}) {
  const { onIntersecting = false } = config;

  const filters = useInsightsStore((state) => state.filters);
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const previousFilters = usePrevious(filters);
  const previousSelectedGroupType = usePrevious(selectedGroupType);

  const filtersChanged =
    !isDeepEqual(previousFilters, filters) || !isDeepEqual(previousSelectedGroupType, selectedGroupType);

  const {
    isIntersecting: hasIntersected,
    setIsIntersecting: setHasIntersected,
    customRef,
  } = useIntersectionObserver({
    initialState: !onIntersecting,
    once: true,
    threshold: 0.2,
  });

  const analyticsFilters = useMemo(() => {
    if (!selectedGroupType) return null;

    const rehabFacilitiesFilterKey = camelCase(selectedGroupType.apiName);

    const { [START_DATE]: start, [END_DATE]: end, [rehabFacilitiesFilterKey]: group, ...rest } = filters;

    return { ...rest, [DATES]: [{ start, end }], groupType: selectedGroupType.id, group };
  }, [filters, selectedGroupType]);

  const fetchData = useCallback(
    async () =>
      Promise.all(
        requests.map(async ({ params, processData }) => {
          if (!analyticsFilters) return;

          const { data } = await retrieveAnalytics(params, analyticsFilters);

          if (!data) return;

          processData(data);

          return data;
        })
      ),
    [analyticsFilters, requests]
  );

  const query = useQuery({
    queryKey: ['analytics', requests, analyticsFilters],
    queryFn: fetchData,
    // Don't let the query fire immediately when filters change - let the useEffect block below
    // handle resetting the intersection observer, which will then trigger the query if/when the
    // element is actually in view.
    enabled: !filtersChanged && (!onIntersecting || hasIntersected),
  });

  useEffect(() => {
    if (filtersChanged) {
      setHasIntersected(false);
    }
  }, [filtersChanged, setHasIntersected]);

  return { loading: query.isPending || query.isRefetching, customRef };
}
