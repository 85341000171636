import { useMemo } from 'react';
import styled from 'styled-components';

import { ACUTE_LOCATION_TYPES, AFFILIATE, HOSPITAL, PAYER, PHYSICIAN_GROUP } from 'constants/locationTypes';
import Episode from 'models/Episode';
import LocationEpisode from 'models/LocationEpisode';
import { AcuteLocationType, AllLocationType } from 'models/LocationType';
import { BodySmall, BodySmallBold, LabelBold } from 'styles/typography';
import AffiliateIcon from 'svg/AffiliateIcon';
import BedIcon from 'svg/BedIcon';
import HospitalIcon from 'svg/HospitalIcon';
import PayerIcon from 'svg/PayerIcon';
import PhysicianGroupIcon from 'svg/PhysicianGroupIcon';
import PhysicianIcon from 'svg/PhysicianIcon';
import StarIcon from 'svg/StarIcon';

type Props = {
  episode: Episode;
  locationEpisode: LocationEpisode;
};

const getOwnerIcon = (type: AllLocationType) => {
  switch (type) {
    case AcuteLocationType.HOSPITAL:
      return <HospitalIcon />;
    case AcuteLocationType.PAYER:
      return <PayerIcon />;
    case AcuteLocationType.PHYSICIAN_GROUP:
      return <PhysicianGroupIcon />;
    default:
      return null;
  }
};

export default function CoManagementTeam(props: Props) {
  const { episode, locationEpisode } = props;
  const ownerType = episode.owner!.locationType;
  const ownerIcon = getOwnerIcon(ownerType.kind);
  const uniqueAffiliates = episode.affiliates.filter(
    (affiliate, index, array) => array.findIndex((a) => a.name === affiliate.name) === index
  );

  const postAcuteName = locationEpisode.rehabInformation.latestRehabFacility.name;
  const rehabFacilityAcronym = locationEpisode.rehabInformation.latestRehabFacilityType;

  const locationTypeOrderPriority = useMemo(() => {
    return [...new Set([episode.owner.locationType.kind, HOSPITAL, PHYSICIAN_GROUP, PAYER, AFFILIATE])];
  }, [episode.owner]);

  const nonOwningAcuteGroups = useMemo(() => {
    return episode.episodeGroups
      ?.filter(({ group }) => group.id !== episode.owner.id && ACUTE_LOCATION_TYPES.includes(group.locationType.kind))
      .sort(
        (a, b) =>
          locationTypeOrderPriority.indexOf(a.group.locationType.kind) -
          locationTypeOrderPriority.indexOf(b.group.locationType.kind)
      );
  }, [episode.episodeGroups, episode.owner.id, locationTypeOrderPriority]);

  return (
    <TableSection>
      <TableHeader>
        <LabelBold>CO-MANAGEMENT TEAM</LabelBold>
      </TableHeader>
      <Row>
        <Column>
          <BedIcon />
          <BodySmall>{rehabFacilityAcronym}</BodySmall>
        </Column>
        <Column>
          <BodySmallBold>{postAcuteName}</BodySmallBold>
        </Column>
      </Row>

      <Row>
        <Column>
          {ownerIcon}
          <BodySmall>{ownerType.name}</BodySmall>
        </Column>
        <Column>
          <BodySmallBold>{episode.owner!.name}</BodySmallBold>
          <div>
            <StarIcon />
          </div>
        </Column>
      </Row>
      {nonOwningAcuteGroups?.map((episodeGroup) => {
        const locationType = episodeGroup.group.locationType.kind;

        return (
          <Row key={episodeGroup.id}>
            <Column>
              {getOwnerIcon(locationType)}
              <BodySmall>{episodeGroup.group.locationType.name}</BodySmall>
            </Column>
            <Column>
              <BodySmallBold>{episodeGroup.group.name}</BodySmallBold>
            </Column>
          </Row>
        );
      })}
      <Row>
        <Column>
          <PhysicianIcon />
          <BodySmall>Physician</BodySmall>
        </Column>
        <Column>
          <BodySmallBold>{episode.physicianTeam?.name || '—'}</BodySmallBold>
        </Column>
      </Row>
      {uniqueAffiliates.map((affiliate) => (
        <Row key={affiliate.id}>
          <Column>
            <AffiliateIcon />
            <BodySmall>Other</BodySmall>
          </Column>
          <Column>
            <BodySmallBold>{affiliate.name}</BodySmallBold>
          </Column>
        </Row>
      ))}
    </TableSection>
  );
}

const TableSection = styled.div`
  padding-bottom: 8px;
`;
const TableHeader = styled.div`
  padding: 24px;
  color: var(--black-50);
`;
const Row = styled.div`
  display: flex;
  padding: 0 24px 16px;
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
`;
