import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import {
  DimensionConstants,
  MetricConstants,
  RouteConstants,
  SortOrder,
  SourceConstants,
} from 'components/insights/constants';
import Card from 'components/insights/layout/Card';
import ChartContainer from 'components/insights/layout/details/ChartContainer';
import { generateExtendedChartConfig } from 'components/insights/layout/details/helpers/responseTimeUtils';
import LocalExport from 'components/insights/layout/details/LocalExport';
import MetricDetailContainer from 'components/insights/layout/details/MetricDetailContainer';
import MetricDetailHeader from 'components/insights/layout/details/MetricDetailHeader';
import TableContainer from 'components/insights/layout/details/TableContainer';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import { DataRow, parseDimensionValues, parseMetricValues } from 'components/insights/layout/helpers/dataUtils';
import useDataFetch from 'components/insights/layout/helpers/useDataFetch';
import { formatDuration } from 'components/shared/charts/helpers';
import Histogram from 'components/shared/charts/Histogram';
import DataTable from 'components/shared/DataTable';
import Toggle from 'components/shared/Toggle';
import { Flags } from 'constants/flags';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import { OWNER_CLIENT_TYPES } from 'models/Client';
import { EscalationTypes } from 'models/Escalation';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';
import { BodySmall } from 'styles/typography';

import { GroupResponseTimeRow, ResponseTimeChartData } from './helpers/utils';

type ResponseTimeDetailProps = {
  escalationType: EscalationTypes;
};

const ResponseTimeDetail = ({ escalationType }: ResponseTimeDetailProps) => {
  const { profile } = useProfile();
  const isMobile = useIsMobile();
  const isPriorityNote = escalationType === EscalationTypes.PriorityNote;

  const [businessHoursOnly, setBusinessHoursOnly] = useState(true);
  const [selectedDimension, setSelectedDimension] = useState<DimensionConstants>(
    profile.isAcute && profile.hasFlag(Flags.Insights) && isPriorityNote
      ? DimensionConstants.PROVIDER_CLIENT
      : isPriorityNote
        ? DimensionConstants.PROVIDER_NAME
        : DimensionConstants.OWNER_GROUP_NAME
  );
  const responseTimeLabel = `${isPriorityNote ? 'Priority' : 'Escalation'} Response Time`;
  const countLabel = isPriorityNote ? 'Priorities' : 'Escalations';
  const filePrefix = isPriorityNote ? 'priority-response-time' : 'escalation-response-time';
  const navigate = useNavigate();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const [chartData, setChartData] = useState<ResponseTimeChartData>({
    categories: [],
    responseTime: [],
    averageResponseTime: 0,
    count: [],
  });

  const metrics = useMemo(
    () => [
      ...(businessHoursOnly ? [MetricConstants.BUSINESS_RESPONSE_TIME] : [MetricConstants.RESPONSE_TIME]),
      MetricConstants.ID_COUNT,
    ],
    [businessHoursOnly]
  );

  const [totalRecords, setTotalRecords] = useState(-1);
  const [averageResponseTime, setAverageResponseTime] = useState(-1);
  const request = useMemo(
    () => ({
      params: {
        dimensions: [selectedDimension],
        metrics,
        source: SourceConstants.ESCALATION_ACKS,
        sortBy: `${MetricConstants.ID_COUNT} ${SortOrder.DESC}, ${DimensionConstants.GROUP_NAME} ${SortOrder.DESC}`,
        rollups: true,
        escalationType: isPriorityNote ? 'priority_note' : 'escalation',
      },
      processData: (data: DataRow[]) => {
        const parsedData = data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, false) as string[];
            const metricValues = parseMetricValues(row);
            const acuteGroupName = dimensionValues[0];
            const count = metricValues[1];
            const time = metricValues[0];
            if (row.grouping === 1) {
              acc.totalRecords = count;
              acc.averageResponseTime = time;
            } else {
              acc.categories.push(acuteGroupName || 'None');
              acc.responseTime.push(time);
              acc.count.push(count);
            }

            return acc;
          },
          {
            categories: [],
            responseTime: [],
            count: [],
            averageResponseTime: 0,
            totalRecords: 0,
          } as {
            categories: string[];
            responseTime: number[];
            count: number[];
            averageResponseTime: number;
            totalRecords: number;
          }
        );

        const { totalRecords, ...chartData } = parsedData;

        setChartData(chartData);

        setTotalRecords(totalRecords);
        setAverageResponseTime(chartData.averageResponseTime);
      },
    }),
    [selectedDimension, metrics, isPriorityNote]
  );
  const getValueString = () => {
    if (averageResponseTime <= 0) return;

    return formatDuration(averageResponseTime, true);
  };
  const { loading } = useDataFetch([request]);
  const chartConfig = useMemo(() => generateExtendedChartConfig(chartData), [chartData]);

  const tableData = useMemo<GroupResponseTimeRow[]>(() => {
    const { categories, responseTime, count } = chartData;
    return categories.map((groupName, i) => ({
      groupName,
      responseTime: formatDuration(responseTime[i], true),
      count: count[i],
    }));
  }, [chartData]);

  const dataTableDisplayHeaders = useMemo(
    () => ({
      [OWNER_CLIENT_TYPES.PAYOR]: 'Payer',
      [OWNER_CLIENT_TYPES.HEALTH_SYSTEM]: 'Hospital',
      [OWNER_CLIENT_TYPES.PHYSICIAN_GROUP]: 'Physician Group',
    }),
    []
  );
  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case DimensionConstants.PLAN_TYPE:
        return 'Plan Type';
      case DimensionConstants.EPISODE_TYPE:
        return 'Episode Type';
      case DimensionConstants.PROVIDER_CLIENT:
        return isPriorityNote
          ? `${selectedGroupType?.displayName} Company`
          : dataTableDisplayHeaders[profile.clientType];
      default:
        return isPriorityNote ? selectedGroupType?.displayName : dataTableDisplayHeaders[profile.clientType];
    }
  }, [dataTableDisplayHeaders, isPriorityNote, profile.clientType, selectedDimension, selectedGroupType?.displayName]);

  const columnHelper = createColumnHelper<GroupResponseTimeRow>();
  const columns = useMemo<ColumnDef<GroupResponseTimeRow, any>[]>(
    () => [
      columnHelper.accessor('groupName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('responseTime', {
        header: responseTimeLabel,
        footer: () => `${formatDuration(averageResponseTime, true)} (Avg)`,
      }),
      columnHelper.accessor('count', {
        header: countLabel,
        footer: () => `${totalRecords} (Total)`,
      }),
    ],
    [columnHelper, getTableHeader, responseTimeLabel, countLabel, averageResponseTime, totalRecords]
  );

  const defaultSort = [
    {
      id: 'count',
      desc: true,
    },
  ];

  const desktopOnlyColumns = ['count'];
  const responseTimeViewBySelectOptions = {
    label: dataTableDisplayHeaders[profile.clientType],
    value: DimensionConstants.OWNER_GROUP_NAME,
  };
  const providerNameOption = {
    label: `${selectedGroupType?.displayName}`,
    value: DimensionConstants.PROVIDER_NAME,
  };
  const priorityNoteViewBySelectOptions = [
    providerNameOption,
    {
      label: `${selectedGroupType?.displayName} Company`,
      value: DimensionConstants.PROVIDER_CLIENT,
    },
  ];
  return (
    <>
      <ToggleContainer>
        <Toggle active={businessHoursOnly} onChange={(val) => setBusinessHoursOnly(val)} disabled={false} />
        <BodySmall>Business Hours Only</BodySmall>
      </ToggleContainer>
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.responseTime.length}
        header={
          <>
            <MetricDetailHeader label={responseTimeLabel} loading={loading} value={getValueString()} />
            <Separator />
            <MetricDetailHeader label={countLabel} loading={loading} value={totalRecords.toString()} />
            {profile.hasFlag(Flags.Insights) && !isMobile && (
              <ViewBySelect
                selectedDimension={selectedDimension}
                groupType={selectedGroupType}
                additionalOptions={isPriorityNote ? priorityNoteViewBySelectOptions : [responseTimeViewBySelectOptions]}
                excludeGroupTypeOptions
                onChange={(selectedOption) => {
                  setSelectedDimension(selectedOption.value);
                }}></ViewBySelect>
            )}
          </>
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <TableContainer>
        <TableTitleContainer>
          <Card.Title>{responseTimeLabel} Overview</Card.Title>
          <LocalExport columns={columns} data={tableData} loading={loading} filePrefix={filePrefix} />
        </TableTitleContainer>
        <DataTable
          columns={columns}
          data={tableData}
          defaultSortBy={defaultSort}
          loading={loading}
          desktopOnlyColumns={desktopOnlyColumns}
        />
      </TableContainer>
    </>
  );
};

export default ResponseTimeDetail;
const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black15};
  margin: 0 24px;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 24px;

  ${BodySmall} {
    font-size: 12px;
  }
`;
