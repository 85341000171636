import { PropsWithChildren } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import DeactivatedUserIcon from 'svg/DeactivatedUserIcon';
import SSOErrorIcon from 'svg/SSOErrorIcon';

import { ErrorState, ErrorStateKind } from './ErrorState';

const FullScreenCover = styled.div`
  background: url(/olio-puzzle-background.png);
  background-size: cover;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const UpperSection = styled.div`
  padding: 24px 36px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MainPanel = styled.div`
  border: 1px solid var(--black-10);
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  max-width: 540px;
`;
const OlioSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
`;

const EmailLink = styled.span`
  font-weight: var(--font-weight-bold);
  color: var(--primary-blue);
`;

function AuthErrorContainer(props: PropsWithChildren) {
  const { children } = props;
  return (
    <FullScreenCover>
      <MainPanel>
        <OlioSection>
          <img src='/olio-logo-standard.svg' />
        </OlioSection>
        <UpperSection>{children}</UpperSection>
      </MainPanel>
    </FullScreenCover>
  );
}

const Content = styled.p`
  margin-top: 24px;
  width: 100%;
  font-size: 20px;
  text-align: center;
  padding: 0 24px;
`;

const BoldSpan = styled.span`
  font-weight: bold;
  display: inline;
`;

function DeactivatedAccount() {
  return (
    <>
      <DeactivatedUserIcon />
      <Content>
        <BoldSpan>Your account is currently deactivated.</BoldSpan> For help with reactivation, please reach out to{' '}
        <EmailLink>support@olio.health</EmailLink>
      </Content>
    </>
  );
}

function AccountLinkError() {
  return (
    <>
      <SSOErrorIcon />
      <Content>
        <BoldSpan>Oh no! Looks like something went wrong linking your SSO account.</BoldSpan>
        <br />
        <br />
        For help resolving this issue, please reach out to <EmailLink>support@olio.health</EmailLink>
      </Content>
    </>
  );
}

function AuthError() {
  const [urlParams] = useSearchParams();
  const errorCode = urlParams.get('code');
  switch (errorCode) {
    case 'user.inactive':
      return (
        <AuthErrorContainer>
          <DeactivatedAccount />
        </AuthErrorContainer>
      );
    case 'user.link-account':
      return (
        <AuthErrorContainer>
          <AccountLinkError />
        </AuthErrorContainer>
      );
    default:
      return <ErrorState kind={ErrorStateKind.SomethingWentWrong} />;
  }
}

export default AuthError;
