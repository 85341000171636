import styled from 'styled-components';

import { H1, H2 } from 'styles/typography';

const FullScreenCover = styled.div`
  background: url(/olio-puzzle-background.png);
  background-size: cover;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const UpperSection = styled.div`
  padding: 62px 36px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MainPanel = styled.div`
  border: 1px solid var(--black-10);
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  max-width: 540px;
`;
const OlioSection = styled.div`
  border-top: 1px solid var(--black-10);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
`;
const TitleText = styled(H1)`
  color: var(--primary-blue);
  margin-top: 24px;
`;
const SubtitleText = styled(H2)`
  text-align: center;
  font-weight: normal;
  color: var(--black-75);
  margin-top: 24px;
  margin-bottom: 50px;
`;

const EmailLink = styled.span`
  font-weight: var(--font-weight-bold);
  color: var(--primary-blue);
`;

export enum ErrorStateKind {
  RequestAccess,
  PatientNotFound,
  SomethingWentWrong,
}
type ErrorStateType = {
  kind: ErrorStateKind;
};
export function ErrorState(props: ErrorStateType) {
  const { kind } = props;
  return (
    <FullScreenCover>
      <MainPanel>
        <UpperSection>
          <img
            src={kind == ErrorStateKind.PatientNotFound ? '/patient-not-found-icon.svg' : '/access-required-icon.svg'}
          />
          <TitleText>
            {kind == ErrorStateKind.PatientNotFound
              ? 'Patient Not Found'
              : kind == ErrorStateKind.RequestAccess
                ? 'Access Required'
                : 'Something went wrong'}
          </TitleText>
          <SubtitleText>
            {kind == ErrorStateKind.PatientNotFound ? (
              'No record exists in Olio for this patient'
            ) : kind == ErrorStateKind.RequestAccess ? (
              <>
                You do not currently have access to Olio. Please contact <EmailLink>support@olio.health</EmailLink> to
                request access.
              </>
            ) : (
              'An error has occurred. Please contact your system administrator.'
            )}
          </SubtitleText>
        </UpperSection>
        <OlioSection>
          <img src='/olio-logo-standard.svg' />
        </OlioSection>
      </MainPanel>
    </FullScreenCover>
  );
}
