import { setMilliseconds, sub } from 'date-fns';
import { camelCase } from 'lodash';
import { create } from 'zustand';

import { DATES, END_DATE, START_DATE } from 'constants/filterKeysConstants';
import GroupType from 'models/GroupType';

import { FilterOption, FilterState } from './types';

const now = setMilliseconds(new Date(), 0);
const thirtyDaysAgo = sub(now, { days: 30 });

export type InsightsFilterState = FilterState<FilterOption[] | string | Date>;

type Actions = {
  setSelectedGroupType: (groupType: GroupType | undefined) => void;
  setSelectedFilters: (filters: InsightsFilterState) => void;
  resetFilters: () => void;
};

type State = {
  selectedGroupType: GroupType;
  filters: InsightsFilterState;
  actions: Actions;
};

export const useInsightsStore = create<State>((set) => ({
  selectedGroupType: new GroupType(),
  filters: {
    [START_DATE]: thirtyDaysAgo,
    [END_DATE]: now,
  },
  actions: {
    setSelectedGroupType: (groupType) => set({ selectedGroupType: groupType }),
    setSelectedFilters: (filters) => set((state) => ({ filters: { ...state.filters, ...filters } })),
    resetFilters: () =>
      set({
        filters: {
          [START_DATE]: thirtyDaysAgo,
          [END_DATE]: now,
        },
      }),
  },
}));

export const useInsightsActions = () => useInsightsStore((state) => state.actions);

export const getAnalyticsFilters = ({ filters, selectedGroupType }: State) => {
  if (!selectedGroupType) return null;

  const rehabFacilitiesFilterKey = camelCase(selectedGroupType.apiName);

  const { [START_DATE]: start, [END_DATE]: end, [rehabFacilitiesFilterKey]: group, ...rest } = filters;

  return { ...rest, [DATES]: [{ start, end }], groupType: selectedGroupType.id, group };
};
