import React, { useRef } from 'react';
import styled from 'styled-components';

import { DischargeFormMeta, DischargeFormValues } from 'components/dischargeForm/DischargeInformation';
import FileInput from 'components/shared/fileInput/FileInput';
import { useForm } from 'context/form';
import { DocumentTypes } from 'models/Attachment';
import { BodySmall } from 'styles/typography';
import TagIcon from 'svg/TagIcon';

export default function DischargeFileRequests() {
  const { values, setValue, setMeta } = useForm<DischargeFormValues, DischargeFormMeta>();
  const attachmentIds = useRef(values.note?.attachments.map((att) => att.id) || []);

  const handleSetValues = (note) => {
    const currentIds = new Set(attachmentIds.current);

    const newIds = note.attachments.map((att) => {
      if (!currentIds.has(att.id)) {
        att.docType = DocumentTypes.DischargeSummaryPostAcute;
      }

      return att.id;
    });

    attachmentIds.current = newIds;

    setValue('note', note);
  };

  return (
    <Container>
      <BodySmall>
        Attach the patient's discharge summary below or click&nbsp;&nbsp;
        <StyledTagIcon width={14} height={16} />
        &nbsp;&nbsp;to mark an attached file as "Discharge Summary (Post-Acute)"
      </BodySmall>
      <FileInput
        values={values.note || { attachments: [] }}
        setValues={handleSetValues}
        onUploading={(uploading) => setMeta('isUploadingAttachments', uploading)}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledTagIcon = styled(TagIcon)`
  margin-bottom: -2px;
`;
