import Client, { ClientOptions } from 'models/Client';
import Credential, { CredentialOptions } from 'models/Credential';
import GroupType, { GroupTypeOptions } from 'models/GroupType';
import type { PERMISSIONS } from 'models/Profile';
import UserPreference, { UserPreferenceOptions } from 'models/UserPreference';
import { NotificationOptions } from 'models/userPreferences/Notification';
import { PortfolioFilterOptions } from 'models/userPreferences/PortfolioFilter';
import UserPreferenceModelFactory from 'models/userPreferences/UserPreferenceModelFactory';

export type UserOptions = {
  id: string;
  active: boolean;
  client: Partial<ClientOptions> | null;
  credential: Partial<CredentialOptions> | null;
  email: string;
  flags: string[];
  groupType: string | null;
  locationType: string | null;
  name: string;
  role: string;
  permissions: Partial<Record<keyof typeof PERMISSIONS, boolean>>;
  preferences: (Partial<UserPreferenceOptions> | Partial<PortfolioFilterOptions> | Partial<NotificationOptions>)[];
  lastLogin: string | null;
  actingAsAcute: boolean;
  actingClient: Partial<ClientOptions> | null;
  actingClientId: string | null;
  enabledProviderTypes: GroupTypeOptions[];
  selectedProviderTypes: GroupTypeOptions[];
};

export function getDefaults(): UserOptions {
  return {
    id: '',
    active: true,
    client: null,
    credential: null,
    email: '',
    flags: [],
    groupType: null,
    locationType: null,
    name: '',
    role: '',
    permissions: {},
    preferences: [],
    lastLogin: null,
    actingAsAcute: false,
    actingClient: null,
    actingClientId: null,
    enabledProviderTypes: [],
    selectedProviderTypes: [],
  };
}

/**
 * User
 * @class User
 * @param {Partial<User>} [options={}]
 * @property {string} id - The id of the user
 * @property {boolean} active - Whether the user is active/inactive
 * @property {Client | null} client - The client the user belongs to
 * @property {Credential | null} credential - The credential of the user
 * @property {string} email - The email of the user
 * @property {string[]} flags - Enabled flags for the user
 * @property {string | null} groupType - The group type of the user
 * @property {string} name - The name of the user
 * @property {string} role - The user's role
 * @property {Record<string, boolean>} permissions - The user's permissions
 * @property {string | null} lastLogin - The last login date of the user
 * @property {boolean} actingAsAcute - Whether the user is acting as acute
 * @property {Client | null} actingClient - The client the user is acting as
 * @property {string | null} actingClientId - The id of the client the user is acting as
 * @property {GroupType[]} enabledProviderTypes - The enabled provider types for the user
 * @property {GroupType[]} selectedProviderTypes - The selected provider types for the user
 * @method fullName - A string containing the user's name and credential title
 * @example const user = new User({ id: '38ce0cea-f787-11ea-8e07-07fe8aa91684' });
 * @returns {User}
 */

export default class User {
  id: string;
  active: boolean;
  client: Client | null;
  credential: Credential | null;
  email: string;
  flags: string[];
  groupType: string | null;
  locationType: string | null;
  name: string;
  role: string;
  permissions: Partial<Record<keyof typeof PERMISSIONS, boolean>>;
  preferences: UserPreference[];
  lastLogin: string | null;
  actingAsAcute: boolean;
  actingClient: Client;
  actingClientId: string | null;
  enabledProviderTypes: GroupType[];
  selectedProviderTypes: GroupType[];

  static getDefaults = getDefaults;

  constructor(options: Partial<UserOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.active = opts.active;
    this.client = opts.client ? new Client(opts.client) : null;
    this.credential = opts.credential ? new Credential(opts.credential) : null;
    this.email = opts.email;
    this.flags = opts.flags;
    this.groupType = opts.groupType;
    this.locationType = opts.locationType;
    this.name = opts.name;
    this.role = opts.role;
    this.permissions = opts.permissions;
    this.preferences = opts.preferences.map((pref) => UserPreferenceModelFactory.get(pref));
    this.lastLogin = opts.lastLogin;
    this.actingAsAcute = opts.actingAsAcute;
    this.actingClient = opts.actingClient?.id ? new Client(opts.actingClient) : new Client(opts.client ?? {});
    this.actingClientId = opts.actingClientId || this.actingClient.id;
    this.enabledProviderTypes = opts.enabledProviderTypes.map((providerType) => new GroupType(providerType));
    this.selectedProviderTypes = opts.selectedProviderTypes.map((providerType) => new GroupType(providerType));
  }

  get fullName() {
    const { credential, name } = this;

    if (credential?.title) {
      return `${name}, ${credential.title}`;
    }

    return name;
  }
}
