import { createContext, useContext, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import CircularProgress from 'components/shared/CircularProgress';
import { useAuth } from 'context/auth';
import FilterValueArray from 'models/filterValues/FilterValueArray';
import FilterValueBoolean from 'models/filterValues/FilterValueBoolean';
import Profile from 'models/Profile';
import { showProfile } from 'services/api/profile';
import { PortfolioSorts } from 'stores/portfolioStore';
import { usePortfolioActions } from 'stores/portfolioStore';
import { colors } from 'styles/theme/colors';

import { ErrorState, ErrorStateKind } from '../components/ErrorState';

type ProfileContextType = {
  profile: Profile;
  loading: boolean;
  refetch: () => void;
};

const ProfileContext = createContext<ProfileContextType>({
  profile: {} as Profile,
  loading: true,
  refetch: () => {},
});

export function ProfileProvider({ children }: { children: React.ReactNode }) {
  const { loading: loadingAuth, token } = useAuth();
  const [ready, setReady] = useState(false);

  const { setFilters, setSorts } = usePortfolioActions();

  const {
    data: profile,
    error,
    isPending,
    refetch,
    isLoadingError,
  } = useQuery({
    queryKey: ['profile'],
    queryFn: showProfile,
    retry: 3,
    retryDelay: 1000,
    enabled: ready,
  });

  const loading = isPending || isLoadingError || loadingAuth;

  // Gives just enough time for AxiosProvider to set the token
  // on the axios instance before we start making requests
  useEffect(() => {
    if (token && !ready) {
      setReady(true);
    }
  }, [ready, token]);

  // Set portfolio filters on load of the user's profile. This needs to be done here so that the
  // mobile navigation has access to it if the user has yet to navigate to the portfolio page.
  useEffect(() => {
    if (profile?.portfolioPreference) {
      const { sorts, ...initialFilters } = profile?.portfolioPreference?.value ?? {};

      const filters = Object.entries(initialFilters).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
          acc[key] = new FilterValueArray(value);
          return acc;
        } else if (typeof value === 'boolean') {
          acc[key] = new FilterValueBoolean({ value: value, name: key });
          return acc;
        }

        return acc;
      }, {});

      setFilters(filters);

      setSorts((sorts ?? {}) as PortfolioSorts);
    }
    // setFilters and setSorts not needed in deps array as they will not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.portfolioPreference]);

  if ((error as AxiosError)?.response?.status === 404) {
    return <ErrorState kind={ErrorStateKind.RequestAccess} />;
  }

  if (loading) {
    return (
      <FullScreen>
        <CircularProgress color={colors.primaryBlue} />
      </FullScreen>
    );
  }

  return <ProfileContext.Provider value={{ profile, loading, refetch }}>{children}</ProfileContext.Provider>;
}

export function useProfile() {
  const context = useContext(ProfileContext);

  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }

  return context;
}

const FullScreen = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
