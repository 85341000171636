import { GenericAbortSignal } from 'axios';

import { applicableTabFilters } from 'components/portfolio/helpers';
import { PortfolioTab } from 'components/portfolio/Portfolio';
import { PHYSICIAN_TEAM } from 'constants/filterKeysConstants';
import Episode, { EpisodeOptions, SupplementalEpisodeInformation } from 'models/Episode';
import FilterValueArray from 'models/filterValues/FilterValueArray';
import FilterValueBoolean from 'models/filterValues/FilterValueBoolean';
import { indexLocationEpisodes } from 'services/api/locationEpisodes';
import { BaseIndexQueryParams } from 'services/api/types';
import http from 'services/http';
import { PortfolioFilterState } from 'stores/portfolioStore';

// Tactically defined types for use by portfolio api code.
// References models, probably does not need to be defined
// as a re-usable type within models
export type PortfolioColumn = {
  data: {
    episode: Episode;
    info: SupplementalEpisodeInformation;
  }[];
  meta: { totalPages: number; totalRecords: number };
};

type LaneResponse = {
  data: (EpisodeOptions & SupplementalEpisodeInformation)[];
  meta: { totalPages: number; totalRecords: number };
};

function extractSupplementalEpisodeInformation(
  d: EpisodeOptions & SupplementalEpisodeInformation
): SupplementalEpisodeInformation {
  return {
    locationEpisodeId: d.id,
    latest: d.latest,
    lengthOfStay: d.lengthOfStay,
    onTrack: d.onTrack,
    hasNewChanges: d.hasNewChanges,
    statusOutOfDate: d.statusOutOfDate,
    planTypeClassification: d.planTypeClassification,
    hasActiveServiceRefusals: d.hasActiveServiceRefusals,
    unacknowledgedEscalations: d.unacknowledgedEscalations,
    unacknowledgedPriorityNotes: d.unacknowledgedPriorityNotes,
  };
}

export type PortfolioFilter = {
  key: string;
  options: { id: string; name: string }[];
  isAttrValueStyle: boolean;
};

function mapPortfolioResponseToColumn(y: LaneResponse): PortfolioColumn {
  return {
    data: y.data.map((x) => ({
      episode: new Episode({ ...x, id: x.episodeId }),
      info: extractSupplementalEpisodeInformation(x),
    })),
    meta: y.meta,
  };
}

type PortfolioResponse = {
  queue: LaneResponse;
  admission: LaneResponse;
  discharged: LaneResponse;
  'in treatment': LaneResponse;
};

type IndexParams = {
  locationType?: string;
  patientState?: string;
  filters?: PortfolioFilterState;
  currentRehabState?: string;
  active?: boolean;
} & BaseIndexQueryParams;

function serializeFilters(filters: PatientCountParams['filters']) {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    if (value instanceof FilterValueArray) {
      if (!value) return acc;

      if (key === PHYSICIAN_TEAM) {
        acc[key] = value.map((x) => x.name);
      } else {
        acc[key] = value.map((x) => x.id).join(',');
      }
    } else if (value instanceof FilterValueBoolean) {
      if (value.value) {
        acc[key] = value.value;
      }
    }
    return acc;
  }, {});
}

export async function indexPortfolio({ filters, ...params }: IndexParams, signal: GenericAbortSignal) {
  const serializedParams = {
    ...params,
    ...serializeFilters(filters ?? {}),
  };

  return (
    http
      // When currentRehabstate=All param is passed, the response is a PortfolioResponse, which has the data
      // separated by lane. Otherwise it is a normal index response, which conforms to a single LaneResponse
      .get<PortfolioResponse | LaneResponse>('/location_episodes/portfolio', { params: serializedParams, signal })
      .then((res) => {
        if ('queue' in res.data) {
          return {
            queue: mapPortfolioResponseToColumn(res.data.queue),
            admission: mapPortfolioResponseToColumn(res.data.admission),
            discharged: mapPortfolioResponseToColumn(res.data.discharged),
            'in treatment': mapPortfolioResponseToColumn(res.data['in treatment']),
          };
        } else {
          return mapPortfolioResponseToColumn(res.data);
        }
      })
  );
}

export async function indexPortfolioLane({ filters, ...params }: IndexParams, signal: GenericAbortSignal) {
  const serializedParams = {
    ...params,
    ...serializeFilters(filters ?? {}),
  };

  return http.get<LaneResponse>('/location_episodes/portfolio', { params: serializedParams, signal }).then((res) => {
    return mapPortfolioResponseToColumn(res.data);
  });
}

type PatientCountParams = {
  tab: PortfolioTab;
  filters: PortfolioFilterState;
  search: string;
};

export async function getPatientCounts({ tab, filters, search }: PatientCountParams, signal: GenericAbortSignal) {
  const applicableFilters = applicableTabFilters(filters, tab as PortfolioTab);

  return indexLocationEpisodes(
    {
      locationType: tab.locationType,
      patientState: tab.patientState,
      active: true,
      pageSize: 1,
      search,
      ...serializeFilters(applicableFilters),
    },
    signal
  );
}

export const portfolioQueryKeys = {
  index: ['portfolio'],
  count: (locationTypeOrPatientState: string) => ['portfolio', 'count', locationTypeOrPatientState],
  lane: (lane: string) => ['portfolio', lane],
};
