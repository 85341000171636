import React from 'react';

import { FormMeta, FormValues, PatientFormMode } from 'components/intake/types';
import DatePickerComponent from 'components/shared/form/DatePicker';
import FormSection from 'components/shared/form/FormSection';
import InputGroup from 'components/shared/form/InputGroup';
import SectionHeader from 'components/shared/form/SectionHeader';
import { useForm } from 'context/form';
import { parseDate } from 'lib/date';

export type PostAcuteInfoSection = {
  isAcute: boolean;
};

function PostAcuteInfoSection(props: PostAcuteInfoSection) {
  const { values, meta, errors, setValue } = useForm<FormValues, FormMeta>();

  const connecting = meta.mode == PatientFormMode.Connecting;
  const visible = props.isAcute && connecting;

  if (!visible) return null;

  return (
    <FormSection>
      <SectionHeader>Post-Acute Information</SectionHeader>
      {connecting ? (
        <InputGroup title='Anticipated Hospital Discharge (optional)' error={errors.antHospitalDischarge}>
          <DatePickerComponent
            selected={parseDate(values.antHospitalDischarge)}
            onChange={(change) => setValue('antHospitalDischarge', change?.toISOString())}
            disabled={false}
          />
        </InputGroup>
      ) : null}
    </FormSection>
  );
}

export default PostAcuteInfoSection;
