import UserPreference, {
  getDefaults as getUserPreferenceDefaults,
  UserPreferenceOptions,
  UserPreferenceType,
} from 'models/UserPreference';
import { PortfolioFilterState, PortfolioSorts } from 'stores/portfolioStore';

export type PortfolioFilterValue = PortfolioFilterState | { sorts?: PortfolioSorts };

export interface PortfolioFilterOptions extends UserPreferenceOptions {
  type: UserPreferenceType.PORTFOLIO_FILTER;
  value: PortfolioFilterValue;
}

export function getDefaults(): PortfolioFilterOptions {
  return {
    ...getUserPreferenceDefaults(),
    type: UserPreferenceType.PORTFOLIO_FILTER,
    value: {},
  };
}

export default class PortfolioFilter extends UserPreference {
  declare value: PortfolioFilterValue;

  constructor(options: Partial<PortfolioFilterOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);

    this.value = opts.value;
  }
}
