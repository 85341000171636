import { GenericAbortSignal } from 'axios';

import { PHYSICIAN_TEAM } from 'constants/filterKeysConstants';
import LocationEpisode from 'models/LocationEpisode';
import { LocationEpisodeOptions } from 'models/LocationEpisode';
import { Paginated } from 'models/Paginated';
import { BaseIndexQueryParams } from 'services/api/types';
import http from 'services/http';
import { PatientFilterState } from 'stores/patientFiltersStore';

export type PatientFilter = {
  key: string;
  options: { id: string; name: string }[];
  isAttrValueStyle: boolean;
};

function serializeFilters(filters: AllPatientsIndexParams['filters']) {
  return Object.entries(filters).reduce((acc, [key, values]) => {
    if (!values.length) return acc;

    if (key === PHYSICIAN_TEAM) {
      acc[key] = values.map((x) => x.name);
    } else {
      acc[key] = values.map((x) => x.id).join(',');
    }

    return acc;
  }, {});
}

type AllPatientsIndexParams = {
  filters: PatientFilterState;
} & BaseIndexQueryParams;
export async function indexAllPatients({ filters, ...params }: AllPatientsIndexParams, signal: GenericAbortSignal) {
  const serializedParams = {
    ...params,
    ...serializeFilters(filters ?? {}),
  };

  return http
    .get<Paginated<LocationEpisodeOptions>>(`/location_episodes/all_patients`, { params: serializedParams, signal })
    .then((response) => ({
      ...response.data,
      data: response.data.data.map((x) => new LocationEpisode(x)),
    }));
}
