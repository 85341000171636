import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { cloneDeep, isEqual, set, unset } from 'lodash';
import * as yup from 'yup';

import { DiscardChangesProvider } from 'context/discardChanges';
import {
  Errors,
  FormContextProps,
  FormMeta,
  FormProviderProps,
  FormValues,
  SetValueOptions,
  ValidateFormOptions,
} from 'context/form/types';
import isDeepEqual from 'lib/isDeepEqual';

const FormContext = createContext({});

export function FormProvider<Values extends object, Meta extends object>({
  onSubmit,
  yupSchema,
  children,
  resetOnInitialValuesChange = false,
  withDiscardChanges = false,
  ...props
}: FormProviderProps<Values, Meta>) {
  // deep copy references to initial values so that when form values change, the initialValues are not affected
  const initialValues = useRef(cloneDeep(props.initialValues ?? {}));
  const initialMeta = useRef(cloneDeep(props.initialMeta ?? {}));

  const [formValues, setFormValues] = useState<FormValues<Values>>(
    cloneDeep(initialValues.current) as FormValues<Values>
  );
  const [formMeta, setFormMeta] = useState<FormMeta<Meta>>(cloneDeep(initialMeta.current));
  const [formErrors, setFormErrors] = useState<Errors>({});

  const [formIsValid, setFormIsValid] = useState(false);
  const formIsDirty = useMemo(() => !isEqual(initialValues.current, formValues), [formValues]);

  const validateField = useCallback(
    async (name: string, value: Partial<Values>) => {
      let errors: Errors = {};

      if (yupSchema) {
        try {
          await yupSchema.validateAt(name, value);
          setFormErrors((prev) => {
            errors = { ...prev };
            unset(errors, name);
            return { ...errors };
          });
        } catch (error) {
          if (yup.ValidationError.isError(error)) {
            setFormErrors((prev) => {
              errors = set(prev, error.path as string, error.message);
              return { ...errors };
            });
          }
        }
      }

      return errors;
    },
    [yupSchema]
  );

  const validateForm = useCallback(
    async (options: ValidateFormOptions = { updateErrorState: true }) => {
      const errors: Errors = {};

      if (yupSchema) {
        try {
          await yupSchema.validate(formValues, { abortEarly: false }); // { abortEarly: false } -> returns all errors
        } catch (error) {
          if (yup.ValidationError.isError(error)) {
            error.inner.forEach((e) => {
              if (e.path) set(errors, e.path, e.message);
            });
          }
        }
      }

      if (options.updateErrorState) setFormErrors(() => ({ ...errors }));

      return errors;
    },
    [yupSchema, formValues]
  );

  const setValue = useCallback(
    async (key: string, value: any, options: SetValueOptions = { validate: true }) => {
      let updatedValues: Values;

      // Read from previous state so the useCallback does not
      // reevaluate every time the values changes
      setFormValues((prev) => {
        updatedValues = set(prev, key as string, value) as Values;
        if (options.validate) validateField(key, updatedValues);
        return { ...updatedValues };
      });
    },
    [validateField]
  );

  const setValues = useCallback(
    async (values: FormValues<Values>, options: SetValueOptions = { validate: true }) => {
      let updatedValues: Values;

      setFormValues((prev) => {
        updatedValues = { ...prev, ...values } as Values;

        if (options.validate) {
          for (const key in updatedValues) {
            validateField(key, updatedValues);
          }
        }

        return { ...updatedValues };
      });
    },
    [validateField]
  );

  const setMeta = useCallback((key: string, value: any) => {
    let updatedMeta: Meta;

    // Read from previous state so the useCallback does not
    // reevaluate every time the meta changes
    setFormMeta((prev) => {
      updatedMeta = set(prev, key as string, value) as Meta;
      return { ...updatedMeta };
    });
  }, []);

  const resetForm = useCallback((values: Partial<Values> = {}, meta: Partial<Meta> = {}) => {
    setFormValues({ ...cloneDeep(initialValues.current), ...values } as Values);
    setFormMeta({ ...cloneDeep(initialMeta.current), ...meta } as Meta);
    setFormErrors({});
  }, []);

  const submitForm = useCallback(async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length) return;

    return onSubmit(formValues, formMeta, { setValue, setMeta, resetForm, setValues });
  }, [validateForm, formValues, onSubmit, formMeta, setValue, setMeta, resetForm, setValues]);

  useEffect(() => {
    const checkFormIsValid = async () => {
      const errors = await validateForm({ updateErrorState: false });
      if (Object.keys(errors).length) {
        setFormIsValid(false);
        return;
      }

      setFormIsValid(formIsDirty);
    };

    checkFormIsValid();
  }, [yupSchema, formIsDirty, formValues, validateForm]);

  // If `resetOnInitialValuesChange` is true and the initialValues have changed, reset the form
  useEffect(() => {
    if (resetOnInitialValuesChange && !isDeepEqual(props.initialValues, initialValues.current)) {
      initialValues.current = cloneDeep(props.initialValues ?? {});
      resetForm();
    }
  }, [props.initialValues, resetForm, resetOnInitialValuesChange]);

  const context = {
    values: formValues,
    errors: formErrors,
    meta: formMeta,
    valid: formIsValid,
    dirty: formIsDirty,
    resetForm,
    setMeta,
    setValue,
    setValues,
    submitForm,
    validateField,
    validateForm,
  };

  const formChildren = typeof children === 'function' ? children(context) : children;

  return (
    <FormContext.Provider value={context}>
      {withDiscardChanges ? (
        <DiscardChangesProvider onConfirm={resetForm} shouldBlock={formIsDirty}>
          {formChildren}
        </DiscardChangesProvider>
      ) : (
        formChildren
      )}
    </FormContext.Provider>
  );
}

export function useForm<Values, Meta = undefined>() {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error('useForm must be used within a FormProvider');
  }

  return context as FormContextProps<Values, Meta>;
}

export function Form({ children, ...rest }: { children: React.ReactNode & React.HTMLAttributes<HTMLFormElement> }) {
  const { submitForm } = useForm();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await submitForm();
  };

  return (
    <form onSubmit={handleSubmit} {...rest}>
      {children}
    </form>
  );
}
