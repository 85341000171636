import { GENDERS } from 'components/intake/PatientInfoSection';
import { FormValues } from 'components/intake/types';
import { ActivityInputValues } from 'components/shared/activityInput/useActivityInput';
import { parseDate, simpleDate } from 'lib/date';
import AttrValue, { AttrValueOptions } from 'models/AttrValue';
import Classification, { ClassificationOptions } from 'models/Classification';
import Group, { GroupOptions } from 'models/Group';
import User from 'models/User';
import { UserOptions } from 'models/User';

import Episode from './Episode';
import RehabState, { RehabStateName, RehabStateOptions } from './RehabState';

export const PATIENT = 'patient';

export interface PatientOptions {
  id: string;
  name: string;
  dateOfBirth: string | Date | null;
  sex: string;
  owner: Partial<GroupOptions> | null;
  hospital: Partial<GroupOptions> | null;
  rehabFacility: Partial<GroupOptions> | null;
  payer: Partial<GroupOptions> | null;
  payor: Partial<GroupOptions> | null;
  planTypeClassification: Partial<ClassificationOptions> | null;
  physicianGroup: Partial<GroupOptions> | null;
  physicianTeam: Partial<AttrValueOptions> | null;
  episodeClassification: Partial<ClassificationOptions> | null;
  episodeId: string;
  locationEpisodeId: string;
  admittedOn: null | Date | string;
  externalId: string | null;
  note: Partial<ActivityInputValues> | null;
  needs: string[];
  antHospitalDischarge: null | Date | string;
  currentRehabState: Partial<RehabStateOptions>;
  caseManager: Partial<UserOptions> | null;
  utilizationManager: Partial<UserOptions> | null;
}

function getDefaults(): PatientOptions {
  return {
    id: '',
    name: '',
    dateOfBirth: '',
    sex: '',
    owner: null,
    hospital: null,
    rehabFacility: null,
    payer: null,
    planTypeClassification: null,
    physicianGroup: null,
    physicianTeam: null,
    episodeClassification: null,
    episodeId: '',
    locationEpisodeId: '',
    admittedOn: null,
    externalId: null,
    note: null,
    needs: [],
    antHospitalDischarge: null,
    payor: null,
    currentRehabState: {},
    caseManager: null,
    utilizationManager: null,
  };
}

/**
 * @class Patient
 * @classdesc Represents a patient in the system
 * @property {string} id - The patient's id
 * @property {string} name - The patient's name
 * @property {Date | null} dateOfBirth - The patient's date of birth
 * @property {string} sex - The patient's sex
 * @property {Group} owner - The patient's owner
 * @property {Group} hospital - The patient's hospital
 * @property {Group} rehabFacility - The patient's rehab facility
 * @property {Group} payer - The patient's payer
 * @property {Classification} planTypeClassification - The patient's plan type classification
 * @property {Group} physicianGroup - The patient's physician group
 * @property {AttrValue | null} physicianTeam - The patient's physician team
 * @property {Classification} episodeClassification - The patient's episode classification
 * @property {string} episodeId - The patient's episode id
 * @property {string} locationEpisodeId - The patient's location episode id
 * @property {null | Date} admittedOn - The patient's admission date
 * @property {string | null} externalId - The patient's external id
 * @property {Note | null} note - The patient's note
 * @property {string[]} needs - The patient's needs
 * @property {Date | null} antHospitalDischarge - The patient's ant hospital discharge date
 * @property {Group} payor - The patient's payor
 * @property {RehabState} currentRehabState - The patient's current rehab state
 * @param {Partial<PatientOptions>} [options={}]
 * @example const patient = new Patient({ id: '123' });
 *
 */
export default class Patient {
  id: string;
  name: string;
  dateOfBirth: Date | null;
  sex: string;
  owner: Group | null;
  hospital: Group | null;
  rehabFacility: Group | null;
  payer: Group | null;
  planTypeClassification: Classification | null;
  physicianGroup: Group | null;
  physicianTeam: AttrValue | null;
  episodeClassification: Classification | null;
  episodeId: string | null;
  locationEpisodeId: string;
  admittedOn: null | Date;
  externalId: string | null;
  note: Partial<ActivityInputValues> | null;
  needs: string[];
  antHospitalDischarge: null | Date;
  payor: Group | null;
  currentRehabState: RehabState | null;
  caseManager: User | null;
  utilizationManager: User | null;

  constructor(opts: Partial<PatientOptions> = {}) {
    const options = { ...getDefaults(), ...opts };
    this.id = options.id;
    this.name = options.name;
    this.dateOfBirth = parseDate(options.dateOfBirth) ?? null;
    this.sex = options.sex;
    this.owner = options.owner ? new Group(options.owner) : null;
    this.hospital = options.hospital ? new Group(options.hospital) : null;
    this.rehabFacility = options.rehabFacility ? new Group(options.rehabFacility) : null;
    this.physicianTeam = options.physicianTeam ? new AttrValue(options.physicianTeam) : null;
    this.physicianGroup = options.physicianGroup ? new Group(options.physicianGroup) : null;
    this.payer = options.payer ? new Group(options.payer) : null;
    this.locationEpisodeId = options.locationEpisodeId;
    this.episodeId = options.episodeId;
    this.admittedOn = parseDate(options.admittedOn);
    this.externalId = options.externalId;
    this.note = options.note;
    this.needs = options.needs;
    this.antHospitalDischarge = parseDate(options.antHospitalDischarge);
    this.payor = options.payor ? new Group(options.payor) : null;
    this.episodeClassification = options.episodeClassification
      ? new Classification(options.episodeClassification)
      : null;
    this.planTypeClassification = options.planTypeClassification
      ? new Classification(options.planTypeClassification)
      : null;
    this.currentRehabState = options.currentRehabState ? new RehabState(options.currentRehabState) : null;
    this.caseManager = options.caseManager ? new User(options.caseManager) : null;
    this.utilizationManager = options.utilizationManager ? new User(options.utilizationManager) : null;
  }

  static get type() {
    return PATIENT;
  }

  static fromEpisodeData(episode: Episode) {
    const patient = episode.patient;
    const rehabFacility = { ...episode.rehabInformation?.latestRehabFacility };

    rehabFacility.groupType = {
      ...rehabFacility.groupType,
      displayName: episode.rehabInformation?.latestRehabFacilityType,
    };

    if (!patient) return new Patient();

    return new Patient({
      ...patient,
      ...episode,
      rehabFacility: rehabFacility?.id ? rehabFacility : null,
      physicianTeam: episode.physicianTeam?.id ? episode.physicianTeam : null,
      physicianGroup: episode.physicianGroup?.id ? episode.physicianGroup : null,
      caseManager: episode.latestLocationEpisode?.caseManagerUser || null,
      utilizationManager: episode.latestLocationEpisode?.utilizationManagerUser || null,
      planTypeClassification: episode.latestLocationEpisode?.planTypeClassification || null,
      episodeClassification: episode.latestLocationEpisode?.episodeClassification || null,
    } as unknown as Partial<PatientOptions>);
  }

  static fromFormValues(values: Partial<FormValues>) {
    return new Patient({
      ...values,
      sex: values.sex?.value,
    } as Partial<PatientOptions>);
  }

  get discharged() {
    return this.currentRehabState?.state === RehabStateName.Discharged;
  }

  intakeFormValues(): Partial<FormValues> {
    return {
      externalId: this.externalId ?? undefined,

      // Patient details
      name: this.name,
      dateOfBirth: this.dateOfBirth,
      sex: GENDERS.find((g) => g.value === this.sex),

      // Episode information
      caseManager: this.caseManager ?? undefined,
      utilizationManager: this.utilizationManager ?? undefined,
      hospital: this.hospital ?? undefined,
      planTypeClassification: this.planTypeClassification ?? undefined,
      episodeClassification: this.episodeClassification ?? undefined,
      physicianTeam: this.physicianTeam ?? undefined,
      admittedOn: this.admittedOn ? this.admittedOn.toISOString() : undefined,
      antHospitalDischarge: this.antHospitalDischarge ? this.antHospitalDischarge.toISOString() : undefined,
      owner: this.owner?.id ? this.owner : undefined,
      payer: this.payer ?? undefined,
      physicianGroup: this.physicianGroup ?? undefined,

      // Post acute information
      rehabFacility: this.rehabFacility ?? undefined,
      locationType: this.rehabFacility
        ? {
            label: this.rehabFacility?.groupType?.displayName ?? '',
            value: this.rehabFacility?.subType ?? this.rehabFacility?.locationType?.kind ?? '',
          }
        : undefined,
    };
  }

  formatNeedsNote() {
    if (!this.externalId) return null; // currently only applies to patients created via Connect
    const needsString = this.needs.length ? `Patient Needs:\n${this.needs.join('\n')}\n\n` : '';
    const noteString = this.note?.text ? `Note:\n${this.note.text}\n\n` : '';
    const dischargeString = this.antHospitalDischarge
      ? `Anticipated Hospital Discharge:\n${simpleDate(this.antHospitalDischarge)}`
      : '';

    return needsString + noteString + dischargeString;
  }

  serialize() {
    const note = this.formatNeedsNote();

    const values = {
      id: this.id,
      name: this.name,
      dateOfBirth: this.dateOfBirth?.toISOString(),
      sex: this.sex,
      admittedOn: this.admittedOn?.toISOString() || null,
      ownerId: this.owner?.id,
      hospitalId: this.hospital?.id,
      locationEpisodeId: this.locationEpisodeId,
      planTypeClassificationId: this.planTypeClassification?.id || null,
      physicianGroupId: this.physicianGroup?.id,
      rehabFacilityId: this.rehabFacility?.id,
      payerId: this.payer?.id,
      episodeClassificationId: this.episodeClassification?.id || null,
      externalId: this.externalId,
      antHospitalDischarge: this.antHospitalDischarge?.toISOString() || null,
      note: note?.length ? note : null,
      attachments: (this.note?.attachments ?? []).map((attachment) => attachment.serialize()),
      caseManagerId: this.caseManager?.id || null,
      utilizationManagerId: this.utilizationManager?.id || null,
      attrValueIds: [] as string[],
    };

    if (this.owner?.locationType?.kind) {
      values[`${this.owner.locationType.kind}Id`] = this.owner.id;
    }

    const physicianTeamId = this.physicianTeam?.id;

    if (physicianTeamId) {
      values.attrValueIds.push(physicianTeamId);
    }

    return values;
  }
}
