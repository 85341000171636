import { useMemo } from 'react';
import { camelCase } from 'lodash';
import styled from 'styled-components';

import FilterBar from 'components/shared/filters/FilterBar';
import FilterMultiSelect from 'components/shared/filters/FilterMultiSelect';
import { FilterSection } from 'components/shared/filters/FilterSlideout';
import DatePicker from 'components/shared/form/DatePicker';
import { createProviderDropdown } from 'constants/filterConfigs';
import { END_DATE, START_DATE } from 'constants/filterKeysConstants';
import GroupType from 'models/GroupType';
import { InsightsFilterState as Filters, useInsightsActions, useInsightsStore } from 'stores/insightsStore';
import { FilterOption } from 'stores/types';

import { useInsightsContext } from './InsightsContainer';
import LevelOfCare from './LevelOfCare';

type InsightsFilterBarProps = {
  activeFilters: Filters;
  filterSections: FilterSection[];
};

const InsightsFilterBar = ({ filterSections, activeFilters }: InsightsFilterBarProps) => {
  const { profile } = useInsightsContext();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const { setSelectedFilters, setSelectedGroupType } = useInsightsActions();

  const providerTypeQuickFilterConfig = createProviderDropdown(selectedGroupType as GroupType);

  const startDate = activeFilters[START_DATE] as Date;
  const endDate = activeFilters[END_DATE] as Date;
  const maxDate = useMemo(() => new Date(), []);
  const minDate = useMemo(() => new Date('05/01/2020'), []);

  const handleClearFilters = () => {
    Object.entries(activeFilters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        setSelectedFilters({ [key]: [] });
      }
    });
  };

  return (
    <FilterBar
      showSearchInput={false}
      applyFilters={(filters: Filters) => {
        setSelectedFilters(filters);
      }}
      filters={activeFilters}
      showResults={false}
      filterSections={filterSections}
      onClearFilters={handleClearFilters}
      quickFilter={
        <FilterMultiSelect
          {...providerTypeQuickFilterConfig}
          key={selectedGroupType?.id}
          placeholder={selectedGroupType?.displayName || ''}
          name='insightsQuickFilter'
          onChange={(val: FilterOption[]) => {
            setSelectedFilters({ [camelCase(selectedGroupType?.apiName)]: val });
          }}
          value={activeFilters[camelCase(selectedGroupType?.apiName as string)] as FilterOption[]}
        />
      }
      title={
        <>
          {profile && selectedGroupType && (
            <LevelOfCare
              enabledProviderTypes={profile.enabledProviderTypes}
              key={selectedGroupType.id}
              initialSelectedProviderType={selectedGroupType}
              onChange={(groupType: GroupType) => {
                setSelectedGroupType(groupType);
              }}
            />
          )}
        </>
      }
      callToAction={
        <DatePickerContainer>
          <DatePicker
            data-gtm-id='insightsStartDateFilter'
            onChange={(val) => setSelectedFilters({ [START_DATE]: val as Date })}
            selected={startDate}
            maxDate={endDate}
            minDate={minDate}
            clearable={false}
            popperPlacement='bottom-start'
          />

          <Emdash>&mdash;</Emdash>

          <DatePicker
            data-gtm-id='insightsEndDateFilter'
            onChange={(val) => setSelectedFilters({ [END_DATE]: val as Date })}
            selected={endDate}
            maxDate={maxDate}
            minDate={startDate}
            clearable={false}
            popperPlacement='bottom-start'
          />
        </DatePickerContainer>
      }
    />
  );
};

export default InsightsFilterBar;

const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    max-width: 160px;

    @media ${({ theme }) => theme.devices.desktop} {
      max-width: 140px;
    }
  }
`;

const Emdash = styled.div`
  margin: 0 8px;
`;
