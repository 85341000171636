import { useMemo, useState } from 'react';

import OlioSolidGauge from 'components/shared/charts/OlioSolidGauge';

import { DimensionConstants, MetricConstants, RouteConstants, SourceConstants } from '../../constants';
import { DataRow, getAverageOes } from '../helpers/dataUtils';
import useDataFetch from '../helpers/useDataFetch';

import MetricTile from './MetricTile';

export default function OlioEngagementScoreTile() {
  const [olioEngagementScore, setOlioEngagementScore] = useState(-1);

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DAYS,
        dimensions: [DimensionConstants.GROUP_NAME],
        metrics: [MetricConstants.OLIO_ENGAGEMENT_SCORE],
      },
      processData: (data: DataRow[]) => {
        setOlioEngagementScore(getAverageOes(data));
      },
    }),
    []
  );

  const { loading, customRef } = useDataFetch([request], { onIntersecting: true });

  return (
    <MetricTile
      ref={customRef}
      data-gtm-id='insightsOES'
      data-cy='insightsOES'
      label='Olio Engagement Score (OES)'
      tooltipText='Measurement of engagement activity in Olio'
      showValue={false}
      loading={loading}
      hasData={olioEngagementScore !== -1}
      navigateTo={RouteConstants.OLIO_ENGAGEMENT_SCORE}>
      <OlioSolidGauge value={olioEngagementScore} label={`${olioEngagementScore}%`} subLabel='OES Goal 100%' />
    </MetricTile>
  );
}
