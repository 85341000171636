import * as yup from 'yup';

import { getLocationTypeForOwningClient, getOwnerText } from 'components/intake/EpisodeInfoSection';
import { PAYER, PHYSICIAN_GROUP } from 'constants/locationTypes';
import { ClientType } from 'models/Client';

export const ownerLocationTypesToRequireHospitalField = [PHYSICIAN_GROUP, PAYER];

export function intakeFormValidation(clientType: ClientType) {
  return yup.object().shape({
    name: yup.string().required('Patient name is required'),
    dateOfBirth: yup.date().required('Date of Birth is required'),
    sex: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable()
      .test('sex', 'Gender is required', (sex) => !!sex?.value),
    physicianTeam: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .nullable()
      .test('physicianTeam', 'Physician is required', (obj) => Boolean(obj?.id)),
    owner: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .nullable()
      .test('owner', `${getOwnerText(clientType)} is required`, (obj) => Boolean(obj?.id)),
    hospital: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .nullable()
      .test('hospital', `Hospital is required`, (obj) =>
        ownerLocationTypesToRequireHospitalField.includes(getLocationTypeForOwningClient(clientType))
          ? Boolean(obj?.id)
          : true
      ),
    rehabFacility: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .nullable()
      .test('rehabFacility', 'Post Acute is required', (obj) => Boolean(obj?.id)),
  });
}
