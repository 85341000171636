import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import LocalExport from 'components/insights/layout/details/LocalExport';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import Histogram from 'components/shared/charts/Histogram';
import DataTable from 'components/shared/DataTable';
import { Flags } from 'constants/flags';
import useIsMobile from 'hooks/useIsMobile';
import { Analytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import { DimensionConstants, MetricConstants, RouteConstants, SortOrder, SourceConstants } from '../../constants';
import { useInsightsContext } from '../../InsightsContainer';
import Card from '../Card';
import { parseDimensionValues, parseMetricValues } from '../helpers/dataUtils';
import useInsightsQuery from '../helpers/useInsightsQuery';

import { AlosChartData, generateExtendedChartConfig } from './helpers/alosUtils';
import { GroupRow } from './helpers/utils';
import ChartContainer from './ChartContainer';
import MetricDetailContainer from './MetricDetailContainer';
import MetricDetailHeader from './MetricDetailHeader';
import TableContainer from './TableContainer';
import ViewBySelect from './ViewBySelect';

export type AlosTableRow = GroupRow & {
  alos: number;
};

const AverageLengthOfStayDetail = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { profile } = useInsightsContext();

  const [selectedDimension, setSelectedDimension] = useState<DimensionConstants>(
    profile.isAcute && profile.hasFlag(Flags.Insights)
      ? DimensionConstants.PROVIDER_CLIENT
      : DimensionConstants.GROUP_NAME
  );

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const [chartData, setChartData] = useState<AlosChartData>({
    categories: [],
    values: [],
    counts: [],
    average: 0,
  });
  const [totalLocationEpisodes, setTotalLocationEpisodes] = useState(-1);

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DISCHARGES,
        dimensions: [selectedDimension],
        metrics: [MetricConstants.AVERAGE_LENGTH_OF_STAY, MetricConstants.ID_COUNT],
        sortBy: `${MetricConstants.ID_COUNT} ${SortOrder.DESC}, ${DimensionConstants.GROUP_NAME} ${SortOrder.DESC}`,
        rollups: true,
      },
      processData: (data: Analytics) => {
        const parsedData = data.data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, false) as string[];
            const metricValues = parseMetricValues(row);

            const groupName = dimensionValues[0];
            const alos = metricValues[0];
            const locationEpisodes = metricValues[1];

            if (!groupName && row.grouping === 1) {
              acc.average = alos;
              acc.totalLocationEpisodes = locationEpisodes;
            } else {
              acc.categories.push(groupName || 'None');
              acc.values.push(alos);
              acc.counts.push(locationEpisodes);
            }

            return acc;
          },
          { categories: [], values: [], counts: [], average: 0, totalLocationEpisodes: 0 } as {
            categories: string[];
            values: number[];
            counts: number[];
            average: number;
            totalLocationEpisodes: number;
          }
        );

        const { totalLocationEpisodes, ...chartData } = parsedData;

        return { chartData, totalLocationEpisodes };
      },
    }),
    [selectedDimension]
  );

  const { loading, query } = useInsightsQuery(request);

  useEffect(() => {
    if (query.data) {
      setChartData(query.data.chartData);
      setTotalLocationEpisodes(query.data.totalLocationEpisodes);
    }
  }, [query.data]);

  const chartConfig = useMemo(() => generateExtendedChartConfig(chartData), [chartData]);

  const getValueString = () => {
    if (chartData.average <= 0) return;

    return `${chartData.average.toFixed(1)} days`;
  };

  const tableData = useMemo<AlosTableRow[]>(() => {
    const { categories, values, counts } = chartData;

    return categories.map((groupName, i) => ({
      groupName,
      alos: values[i],
      locationEpisodes: counts[i],
    }));
  }, [chartData]);

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case DimensionConstants.PLAN_TYPE:
        return 'Plan Type';
      case DimensionConstants.EPISODE_TYPE:
        return 'Episode Type';
      case DimensionConstants.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      case DimensionConstants.REASON:
        return 'Disposition';
      default:
        return selectedGroupType?.displayName;
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  const columnHelper = createColumnHelper<AlosTableRow>();
  const columns = useMemo<ColumnDef<AlosTableRow, any>[]>(
    () => [
      columnHelper.accessor('groupName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('locationEpisodes', {
        header: 'Patients',
        footer: () => `${totalLocationEpisodes} (Total)`,
      }),
      columnHelper.accessor('alos', {
        header: 'Average Length of Stay',
        cell: (data) => `${data.getValue().toFixed(1)} days`,
        footer: () => `${chartData.average.toFixed(1)} days (Avg)`,
      }),
    ],
    [chartData.average, columnHelper, totalLocationEpisodes, getTableHeader]
  );

  const desktopOnlyColumns = ['locationEpisodes'];

  const defaultSort = [
    {
      id: 'locationEpisodes',
      desc: true,
    },
  ];

  return (
    <>
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.values.length}
        header={
          <>
            <MetricDetailHeader label='Average Length of Stay' loading={loading} value={getValueString()} />
            {profile.hasFlag(Flags.Insights) && !isMobile && (
              <ViewBySelect
                selectedDimension={selectedDimension}
                groupType={selectedGroupType}
                additionalOptions={[{ label: 'Disposition', value: DimensionConstants.REASON }]}
                onChange={(selectedOption) => {
                  setSelectedDimension(selectedOption.value);
                }}></ViewBySelect>
            )}
          </>
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <TableContainer>
        <TableTitleContainer>
          <Card.Title>Average Length of Stay Overview</Card.Title>
          <LocalExport columns={columns} data={tableData} loading={loading} filePrefix='average-length-of-stay' />
        </TableTitleContainer>
        <DataTable
          columns={columns}
          data={tableData}
          defaultSortBy={defaultSort}
          desktopOnlyColumns={desktopOnlyColumns}
          loading={loading}
        />
      </TableContainer>
    </>
  );
};

export default AverageLengthOfStayDetail;
