import { useState } from 'react';
import styled from 'styled-components';

import Menu from 'components/shared/menu';
import GroupType from 'models/GroupType';
import colors from 'styles/theme/colors';
import DropdownCaretIcon from 'svg/DropdownCaretIcon';

type Props = {
  enabledProviderTypes: GroupType[];
  onChange: (groupType: GroupType) => void;
  initialSelectedProviderType: GroupType;
};

export default function LevelOfCare({ enabledProviderTypes, onChange, initialSelectedProviderType }: Props) {
  const [selectedProviderType, setSelectedProviderType] = useState(initialSelectedProviderType);

  const showMenu = enabledProviderTypes.length > 1;

  return (
    <Menu $size='small'>
      <Menu.Trigger>
        <Container showMenu={showMenu}>
          <Title>{selectedProviderType.displayName} Insights</Title>
          {showMenu && <DropdownCaretIcon height={12} width={10} fill={colors.black} />}
        </Container>
      </Menu.Trigger>
      <Menu.Content position='center'>
        {showMenu &&
          enabledProviderTypes.map((providerType, i) => (
            <Menu.Item
              key={i}
              $active={providerType.displayName === selectedProviderType?.displayName}
              onClick={() => {
                onChange(providerType);
                setSelectedProviderType(providerType);
              }}>
              {providerType.displayName}
            </Menu.Item>
          ))}
      </Menu.Content>
    </Menu>
  );
}

const Container = styled.div<{ showMenu: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ showMenu }) => (showMenu ? 'pointer' : 'default')};
`;

const Title = styled.div`
  color: ${colors.black};
  font-size: 20px;
  font-weight: bold;
  margin-right: 8px;
`;
