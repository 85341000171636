import {
  COMANAGEMENT_TEAM,
  HOSPITAL_DROPDOWN,
  PAYER_DROPDOWN,
  PHYSICIAN_GROUP_DROPDOWN,
} from 'constants/filterConfigs';
import { ClientType } from 'models/Client';

export const renderableFilters = (actingClientType: string) => {
  let filters = [HOSPITAL_DROPDOWN];

  switch (actingClientType) {
    case ClientType.PAYOR:
      filters = [PAYER_DROPDOWN];
      break;
    case ClientType.PHYSICIAN_GROUP:
      filters = [PHYSICIAN_GROUP_DROPDOWN];
      break;
  }

  return [{ title: COMANAGEMENT_TEAM, filters }];
};
