import { useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Episode from 'models/Episode';
import { SupplementalEpisodeInformation } from 'models/Episode';
import Profile from 'models/Profile';
import { RehabStateName } from 'models/RehabState';
import { colors } from 'styles/theme/colors';
import { Label, LabelBold, LabelSmall } from 'styles/typography';
import GroupIcon from 'svg/GroupIcon';
import NoteIcon from 'svg/NoteIcon';
import PriorityBadgeRed from 'svg/PriorityBadgeRed';
import WarnIcon from 'svg/WarnIcon';

import { SkeletonBlurb } from '../Containers';

const EscalationHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 22px;
  background: ${() => colors.accentRed};
  color: ${() => colors.white};
  border-radius: 6px 6px 0 0;
  & svg {
    margin-right: 5px;
  }
`;
const WarningLabel = styled(Label)`
  color: white;
`;
const LOSBadge = styled.div<{ patientOnTrack: string | null }>`
  border: 2px solid transparent;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ patientOnTrack }) => {
    if (!patientOnTrack) return colors.black05;

    return {
      Yes: colors.accentGreen,
      Unsure: colors.accentYellow,
      No: colors.accentRed,
    }[patientOnTrack];
  }};
`;
const LOSNumber = styled(LabelBold)<{ lengthOfStay: number; patientOnTrack: string | null }>`
  color: ${({ patientOnTrack }) =>
    patientOnTrack && ['No', 'Yes'].includes(patientOnTrack) ? colors.white : colors.black};
  font-size: ${({ lengthOfStay }) => (lengthOfStay >= 1000 ? 10 : 12)}px;
`;

const EmptyPatientName = styled(SkeletonBlurb)`
  width: 100%;
  height: 16px;
  margin-bottom: 4px;
`;
const EmptyPatientPlanType = styled(SkeletonBlurb)`
  height: 14px;
  width: 80px;
`;
const EmptyBadge = styled(SkeletonBlurb)`
  border: 2px solid transparent;
  border-radius: 50%;
  height: 24px;
  width: 24px;
`;
const EmptyRehabFacilityContainer = styled(SkeletonBlurb)`
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  width: 120px;
  margin-top: 15px;
`;

export function SkeletonPatientCard(_props: object) {
  return (
    <CardBody unacknowledgedEscalations={0} unacknowledgedPriorityNotes={0}>
      <Content indicateContinuedCare={false} unacknowledgedEscalations={0} unacknowledgedPriorityNotes={0}>
        <TopInfoContainer>
          <PatientInfoContainer>
            <EmptyPatientName />
            <EmptyPatientPlanType />
          </PatientInfoContainer>
          <EmptyBadge />
        </TopInfoContainer>
        <BottomInfoContainer>
          <EmptyRehabFacilityContainer />
        </BottomInfoContainer>
      </Content>
    </CardBody>
  );
}

type PortfolioCardType = {
  episode: Episode;
  info: SupplementalEpisodeInformation;
  profile: Profile;
  invalidateColumn: () => void;
};
export default function DraggablePatientCard(props: PortfolioCardType) {
  const {
    profile,
    episode,
    info: { hasActiveServiceRefusals, locationEpisodeId },
    invalidateColumn,
  } = props;
  const {
    id,
    rehabInformation: {
      latestRehabFacility: { id: rehabFacilityId },
    },
    currentRehabState: rehabState,
  } = episode;

  const isMobile = useMediaQuery({
    query: '(max-width: 800px)',
  });
  const isDraggable =
    !isMobile &&
    !hasActiveServiceRefusals &&
    (profile?.permissions['rehabStateAdmissionCreate'] || profile?.permissions['rehabStateDischargeCreate']) &&
    (profile?.isAcute || profile?.allowedGroupIds.includes(rehabFacilityId));

  const [{ isDragging }, dragSource] = useDrag<any, any, { isDragging: boolean }>({
    item: {
      type: 'card',
      episode,
      locationEpisodeId,
      rehabState,
      invalidateColumn,
    },
    type: 'card',
    canDrag: () => !!isDraggable,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <DragContainer
      key={id}
      ref={dragSource}
      data-id={id}
      data-dd-action-name={`Patient card: ${id}`}
      style={{ opacity: isDragging ? 0 : 1 }}
      draggable={isDraggable}>
      <PatientCard {...props} />
    </DragContainer>
  );
}

export function PatientCard(props: PortfolioCardType) {
  const {
    episode,
    info: {
      hasActiveServiceRefusals,
      hasNewChanges,
      latest,
      onTrack,
      planTypeClassification,
      lengthOfStay,
      statusOutOfDate,
      unacknowledgedEscalations,
      unacknowledgedPriorityNotes,
    },
  } = props;
  const {
    id,
    patient: { name },
    trackLos,
    rehabInformation: {
      latestRehabFacilityType: rehabType,
      latestRehabFacility: { name: rehabFacility },
    },
    currentRehabState: rehabState,
  } = episode;

  const days = trackLos ? lengthOfStay : null;
  const showLengthOfStayBadge =
    typeof days === 'number' || (onTrack && rehabState.state === RehabStateName.InTreatment);
  const patientOnTrack = useMemo(() => {
    return rehabState?.state !== RehabStateName.InTreatment || statusOutOfDate ? null : onTrack;
  }, [onTrack, rehabState, statusOutOfDate]);
  const navigate = useNavigate();
  return (
    <>
      {!!unacknowledgedEscalations && (
        <EscalationHeader data-cy='escalationHeader'>
          <WarnIcon color={colors.white} />
          <WarningLabel>Escalation</WarningLabel>
        </EscalationHeader>
      )}
      <CardBody
        data-cy='cardBody'
        onClick={() => {
          navigate(`/patients/${id}`);
        }}
        unacknowledgedEscalations={unacknowledgedEscalations}
        unacknowledgedPriorityNotes={unacknowledgedPriorityNotes}>
        <Content
          indicateContinuedCare={!latest}
          unacknowledgedEscalations={unacknowledgedEscalations}
          unacknowledgedPriorityNotes={unacknowledgedPriorityNotes}>
          <TopInfoContainer>
            <PatientInfoContainer>
              <PatientName>{name}</PatientName>
              <PatientPlanType>{planTypeClassification?.name || '—'}</PatientPlanType>
            </PatientInfoContainer>
            {showLengthOfStayBadge && (
              <LOSBadge data-cy='losbadge' patientOnTrack={patientOnTrack}>
                <LOSNumber lengthOfStay={lengthOfStay} patientOnTrack={patientOnTrack}>
                  {lengthOfStay}
                </LOSNumber>
              </LOSBadge>
            )}
          </TopInfoContainer>
          <BottomInfoContainer>
            <RehabFacilityContainer>
              <GroupIcon width={18} height={18} color={colors.black} />
              <RehabFacilityName title={`${rehabType} - ${rehabFacility}`}>
                {rehabType} - {rehabFacility}
              </RehabFacilityName>
            </RehabFacilityContainer>
            <AlertsContainer data-cy='patientCardAlertsContainer'>
              {!!unacknowledgedPriorityNotes && <PriorityBadgeRed />}
              {hasNewChanges && <NoteIcon width={13} height={13} color={colors.primaryBlue} />}
              {hasActiveServiceRefusals && <Chip>Refused</Chip>}
            </AlertsContainer>
          </BottomInfoContainer>
        </Content>
      </CardBody>
    </>
  );
}

const DragContainer = styled.div`
  ${(props) => (props.draggable ? 'cursor: move; cursor: grab;' : 'cursor: pointer;')};
`;

const CardBody = styled.div<{
  unacknowledgedEscalations: number;
  unacknowledgedPriorityNotes: number;
}>`
  cursor: pointer;
  border: ${({ unacknowledgedPriorityNotes }) =>
    unacknowledgedPriorityNotes ? `1.5px solid ${colors.accentRed}` : null};
  border-radius: ${({ unacknowledgedEscalations }) => (unacknowledgedEscalations ? '0 0 6px 6px' : '6px')};
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  background: ${() => colors.white};
`;

const Content = styled.div<{
  indicateContinuedCare: boolean;
  unacknowledgedEscalations: number;
  unacknowledgedPriorityNotes: number;
}>`
  padding: 10px 8px 10px 12px;
  border-left: ${({ indicateContinuedCare }) => (indicateContinuedCare ? `5px solid ${colors.accentGreen}` : null)};
  border-radius: ${({ unacknowledgedPriorityNotes, unacknowledgedEscalations }) =>
    unacknowledgedPriorityNotes || unacknowledgedEscalations ? '0 0 6px 6px' : '6px'};
`;

const TopInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const BottomInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
  width: 100%;
`;

const PatientName = styled(LabelBold)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PatientPlanType = styled(LabelSmall)`
  color: ${() => colors.black75};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RehabFacilityContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  padding: 2px;
  padding-right: 5px;
  background: ${() => colors.black05};
  min-width: 0px;
  & > svg {
    min-width: 18px;
    margin-right: 5px;
  }
`;

const RehabFacilityName = styled(LabelSmall)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AlertsContainer = styled.div`
  display: flex;
  /* This was needed to prevent the alert svgs from being resized smaller
  with smaller resolutions on IE11 */
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 3px;
  & > * {
    margin-left: 6px;
  }
`;

const Chip = styled.div`
  background-color: ${() => colors.black75};
  padding: 4px;
  font-size: 8px;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 100px;
`;
