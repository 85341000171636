import { GenericAbortSignal } from 'axios';

import { InfiniteData } from '@tanstack/query-core';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import Escalation, { EscalationOptions } from 'models/Escalation';
import { Paginated } from 'models/Paginated';
import { activityQueryKeys } from 'services/api/activity';
import { BaseIndexQueryParams, BaseMutationParams, BaseQueryParams } from 'services/api/types';
import http from 'services/http';
import { useToastActions } from 'stores/toastStore';

export enum EscalationType {
  Default = 'default',
  Priority = 'priority',
}

export const escalationsQueryKeys = {
  index: (...rest: any[]) => ['escalations', 'index', ...rest] as const,
  count: (...rest: any[]) => ['escalations', 'count', ...rest] as const,
  show: (id: string) => ['escalations', id] as const,
};

type IndexParams = {
  episode?: string;
  type: EscalationType;
  acknowledged?: boolean;
} & BaseIndexQueryParams;
export async function indexEscalations(params: IndexParams, signal: GenericAbortSignal) {
  return http.get<Paginated<EscalationOptions>>(`escalations`, { params, signal }).then((res) => ({
    ...res.data,
    data: res.data.data.map((x) => new Escalation(x)),
  }));
}

type ShowParams = {
  id: string;
} & BaseQueryParams;
export async function showEscalation({ id, ...params }: ShowParams) {
  return http.get<EscalationOptions>(`escalations/${id}`, { params }).then((res) => new Escalation(res.data));
}

type UpdateParams = { id: string } & BaseMutationParams;
export async function updateEscalation({ id, ...params }: UpdateParams) {
  return http.put<EscalationOptions>(`escalations/${id}`, { params }).then((res) => new Escalation(res.data));
}

export function useInfiniteEscalations<TSelectResult>(
  options: {
    select?: (data: InfiniteData<Paginated<Escalation>, number>) => TSelectResult;
    enabled?: boolean;
  },
  queryParams: IndexParams
) {
  return useInfiniteQuery({
    queryKey: escalationsQueryKeys.index(queryParams),
    queryFn: ({ pageParam, signal }) => indexEscalations({ ...queryParams, page: pageParam }, signal),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
    refetchIntervalInBackground: true,
    refetchInterval: 60000,
    staleTime: 60000,
    ...options,
  });
}

export function useAcknowledgeEscalation() {
  const { addToast } = useToastActions();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateEscalation,
    onSuccess: (updatedEscalation) => {
      queryClient.invalidateQueries({
        queryKey: escalationsQueryKeys.show(updatedEscalation.id),
      });

      queryClient.invalidateQueries({
        queryKey: escalationsQueryKeys.count(),
      });

      queryClient.invalidateQueries({
        queryKey: activityQueryKeys.index(),
      });

      queryClient.setQueriesData<InfiniteData<Paginated<Escalation>>>(
        { queryKey: escalationsQueryKeys.index() },
        (prevData) => {
          if (!prevData) return prevData;

          return {
            ...prevData,
            pages: prevData.pages.map((page) => ({
              ...page,
              data: page.data.map((x) => (x.id === updatedEscalation.id ? updatedEscalation : x)),
            })),
          };
        }
      );
    },
    onError: () => {
      addToast({ text: 'Something went wrong. Please try again.' });
    },
  });
}
